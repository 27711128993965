export const getPositions = (
  target: any,
  body: HTMLElement
) => {
  const elements =
           typeof target === 'string'
             ? Array.from(body.getElementsByClassName(target))
             : target
  const bodyRect = body.getBoundingClientRect()
  if (!elements || elements.length === 0) {
    return []
  }
  return (
    elements
      ?.filter(el=>!!el)
      ?.map((el: any, idx: number) => {
        const rect = el.getBoundingClientRect()
        return {
          id:     idx,
          refIdx: parseInt(el.dataset['idx']) || -1,
          refId:  parseInt(el.dataset['id']) || -1,
          x:      rect.left - bodyRect.left,
          y:      rect.top - bodyRect.top,
          h:      rect.height,
          w:      rect.width,
        }
      }) || []
  )
}

export const getPositionsFromItems = (items, events) => {
  // potentialy performance improvements we can use Map instead of array
  const newPositions = items
    .filter(({ index }) => index < events.length)
    .map((item) => ({
      id: item.index,
      a: false, // active
      y: item.start,
      h: item.size,
      t: events[item.index].start,
    }))
  return newPositions
}
